import React from "react";
import {graphql} from "gatsby";
import {MDXRenderer} from "gatsby-plugin-mdx";
import Slider from "react-slick";
import Footer from "../components/Footer/Footer";
import RelatedProducts from "../components/RelatedProducts/RelatedProducts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShoppingCart} from "@fortawesome/free-solid-svg-icons";
import useTranslations from "../components/useTranslations";
import parse from "html-react-parser"

export function change_img(url, id) {
	url = Object.values(url);
	document.getElementById(id).src = url;
}

const spSettings = {
	dots: true,
};

const Product = ({ data: { mdx } }) => {
	const { yen, taxIncluded, calibration, jcss_accredited_calibration, catalog, manual, download } = useTranslations();

	return (
		<div className="blogpost">
			<div className="top-container flex row-to-column">
				{/*PC*/}
				<div className="pc flex row">
					<div className="product-images flex column">
						{mdx.frontmatter.productImages.map((productImage, index) => (
							<input
								type="image"
								className="image-sub"
								onClick={() => {
									change_img({ productImage }, "img");
								}}
								src={productImage}
								alt={`${mdx.frontmatter.name}-${index + 1}`}
							/>
						))}
					</div>
					<div className="product-image">
						<img
							className="image-main"
							id="img"
							src={mdx.frontmatter.productImages[0]}
							alt=""
						/>
					</div>
				</div>
				{/*PC*/}

				{/*SP*/}
				<div className="sp">
					<Slider className="product-images" {...spSettings}>
						{mdx.frontmatter.productImages.map((productImage) => (
							<img className="image" src={productImage} alt="" />
						))}
					</Slider>
				</div>
				{/*SP*/}

				<div className="product-contents">
					{mdx.frontmatter.sku !== "" && (
						<p className="product-sku">No.{mdx.frontmatter.sku}</p>
					)}
					{mdx.frontmatter.name !== "" && (
						<h1 className="product-name">{mdx.frontmatter.name}</h1>
					)}
					{mdx.frontmatter.model !== "" && (
						<p className="product-model">{mdx.frontmatter.model}</p>
					)}
					{mdx.frontmatter.price !== "" && (
						<>
							{mdx.fields.locale === "ja" &&
								<p className="product-price">
									<span>{mdx.frontmatter.price}</span>
									{yen}
									{taxIncluded}
								</p>
							}
						</>
					)}
					{mdx.fields.locale === "ja" && mdx.frontmatter.ecLink &&
						<div className="product-to-cart">
							<a className="flex center" href={mdx.frontmatter.ecLink}>
								ONLINE SHOP
								<FontAwesomeIcon icon={faShoppingCart}/>
							</a>
						</div>
					}
					<div className="product-contents-js">
						{mdx.frontmatter.jan !== null && (
							<p className="product-jan">
								<span>JAN code</span> : {mdx.frontmatter.jan}
							</p>
						)}
						{mdx.frontmatter.calibration !== null && (
							<p className="product-calibration">
								<span>{calibration}</span> : {mdx.frontmatter.calibration}
							</p>
						)}
						{mdx.frontmatter.jcss_accredited_calibration !== null && (
							<p className="product-jcss-accredited-calibration">
								<span>{jcss_accredited_calibration}</span> : {mdx.frontmatter.jcss_accredited_calibration}
							</p>
						)}
						{mdx.frontmatter.shortDescription !== "" && (
							<p className="product-short-desc">
								{parse(mdx.frontmatter.shortDescription)}
							</p>
						)}
						{mdx.frontmatter.variants.length > 0 &&
						<div className="product-variants flex">{
							mdx.frontmatter.variants.map(((variant, index) =>
								variant && <a className="product-variants__item" href={mdx.frontmatter.variantPaths[index]}>{variant}</a>))
						}
						</div>
						}
					</div>

					{/*PC*/}
					<ul className="pc product-pdfs flex">
						{mdx.frontmatter.productCatalog !== "" && (
							<ul className="product-catalog-pdf">
								<li className="flex center">
									<p>{catalog}</p>
								</li>
								<li className="flex center">
									<a href={mdx.frontmatter.productCatalog}>
										<img src={`/asset/PDF_32.png`} alt="" />
									</a>
								</li>
							</ul>
						)}
						{mdx.frontmatter.productManual !== "" && (
							<ul className="product-manual-pdf">
								<li className="flex center">
									<p>{manual}</p>
								</li>
								<li className="flex center">
									<a href={mdx.frontmatter.productManual}>
										<img src={`/asset/PDF_32.png`} alt="" />
									</a>
								</li>
							</ul>
						)}
					</ul>
					{/*PC*/}

					{/*SP*/}
					<div className="sp product-pdfs flex column center">
						{mdx.frontmatter.productCatalog !== "" && (
							<a
								className="product-catalog-pdf flex center"
								href={mdx.frontmatter.productCatalog}
							>
								{catalog} {download}
							</a>
						)}
						{mdx.frontmatter.productManual !== "" && (
							<a
								className="product-manual-pdf flex center"
								href={mdx.frontmatter.productManual}
							>
								{manual} {download}
							</a>
						)}
					</div>
					{/*SP*/}
				</div>
			</div>
			<div className="product-mdx-contents">
				<MDXRenderer>{mdx.body}</MDXRenderer>
			</div>
			{mdx.frontmatter.relatedProducts[0].length !== 0 && (
				<RelatedProducts
					relatedProducts={mdx.frontmatter.relatedProducts}
					locale={mdx.fields.locale}
				/>
			)}
			<Footer />
		</div>
	);
};

export default Product;

export const query = graphql`
	query Product($locale: String!, $sku: String!) {
		mdx(
			frontmatter: { sku: { eq: $sku } }
			fields: { locale: { eq: $locale } }
		) {
			frontmatter {
				sku
				name
				model
				price
				ecLink
				jan
				shortDescription
				productCatalog
				productManual
				productImages
				categories
				relatedProducts
				variantPaths
				variants
				calibration
				jcss_accredited_calibration
			}
			fields {
				locale
			}
			body
		}
	}
`;
