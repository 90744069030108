import React from "react"
import Slider from "react-slick";
import {graphql, StaticQuery} from "gatsby";
import LocalizedLink from "../localizedLink";

const RelatedProducts = (props) => (
    <StaticQuery
        query={
            graphql`
              query relatedProduct {
                allMdx(
                  filter: {fileAbsolutePath: {regex: "/(akeneo)/.*\\\\.mdx$/"}}
                ) {
                   edges{
                       node{
                          frontmatter {
                            sku
                            name
                            model
                            price
                            productImages
                            relatedProducts
                          }
                          fields {
                            locale
                          }
                          parent {
                            ... on File {
                              relativeDirectory
                            }
                          }
                       }
                   }
                }
            }
            `
        }
        render={(data) => {
            const relatedProductslist = data.allMdx.edges
            const propsRelatedProducts = props.relatedProducts
            const propsLocale = props.locale
            const relatedProducts = relatedProductslist.filter(({node: item}) => {
                return (propsRelatedProducts.includes(item.frontmatter.sku))
            })
            const localeRelatedProducts = relatedProducts.filter(({node: item}) => {
                return (item.fields.locale === propsLocale)
            }).sort((a, b) => {
                return a.node.frontmatter.sku > b.node.frontmatter.sku ? 1 : -1
            })
            let pcSlidesToShow;
            if (localeRelatedProducts.length < 4) {
                pcSlidesToShow = localeRelatedProducts.length
            }else {
                pcSlidesToShow = 4
            }
            let spSlidesToShow;
            if (localeRelatedProducts.length === 1) {
                spSlidesToShow = 1
            }else {
                spSlidesToShow = 2
            }
            const pcSettings = {
                dots: true,
                arrows: true,
                slidesToShow: pcSlidesToShow,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: spSlidesToShow,
                            arrows: false
                        }
                    }
                ]
            };
            return (
                <>
                    <div id="related-products" className="related-contents">
                        {props.locale === 'ja'?
                            <>
                                <h3 className="related-contents-title">関連製品・オプション</h3>
                                <Slider className="related-products" {...pcSettings}>
                                    {localeRelatedProducts.map(({node: item}) => (
                                        <LocalizedLink className="related-product" to={`/${item.parent.relativeDirectory}/`}>
                                            <img className="related-product-image" src={item.frontmatter.productImages[0]}
                                                 alt=""/>
                                            <p className="related-product-sku">{item.frontmatter.sku}</p>
                                            <p className="related-product-name">{item.frontmatter.name}</p>
                                            <p className="related-product-model">{item.frontmatter.model}</p>
                                            <p className="related-product-price"><span>{item.frontmatter.price}</span>円(税込)</p>
                                        </LocalizedLink>
                                    ))}
                                </Slider>
                            </>:
                            <>
                                <h3 className="related-contents-title">Related Product / Option</h3>
                                <Slider className="related-products" {...pcSettings}>
                                    {localeRelatedProducts.map(({node: item}) => (
                                        <LocalizedLink className="related-product" to={`/${item.parent.relativeDirectory}/`}>
                                            <img className="related-product-image" src={item.frontmatter.productImages[0]}
                                                 alt=""/>
                                            <p className="related-product-sku">{item.frontmatter.sku}</p>
                                            <p className="related-product-name">{item.frontmatter.name}</p>
                                            <p className="related-product-model">{item.frontmatter.model}</p>
                                        </LocalizedLink>
                                    ))}
                                </Slider>
                            </>

                        }
                    </div>
                </>
            );
        }}
    />
)

export default RelatedProducts
